<template>
  <div class="policy-detail-container">
    <div class="banner-size">
      <div class="banner-limit banner-info" style="width:1350px;">
        <p style="font-size: 20px;margin-left:100px;;">政策详情</p>
        <!-- <span class="banner-fMax">{{getTitle()}}</span> -->
        <!-- <span class="banner-fMin banner-other">让政府扶持像网购一样便捷</span> -->
      </div>
    </div>
    <div
      class="banner-limit flex-column flex-start policy-detail-content"
      ref="noticeDDetail"
    >
      <!--侧边栏导航目录,是扶持政策则显示-->
      <!--      <div v-if="collectType==2" style="position: fixed;left:100px">
        <Anchor show-ink offset-top="offsetTop">
          <AnchorLink
            v-for="(menu, i) in menus"
            :key="i"
            :href="`#${menu.name}`"
            :title="menu.category">
          </AnchorLink>
        </Anchor>
      </div> -->
      <div v-if="itemData" class="flex-row-between detail-wrap">
        <div class="flex-column flex-start detail-left" style="background-color: #fff;padding:0 15px;">
          <!-- 标题 -->
          <!-- <div
            class="detail-title"
            style="border-bottom: 3px solid #333; padding-bottom: 10px"
          >
            <h2>{{ itemData.name }}</h2>
            <div class="flex-row-between title-wrap">
              <p style="width: 40%">政策文号：{{ itemData.fwzh }}</p>
              <p style="width: 40%">政策级别：{{ itemData.areaname }}</p>
              <p style="width: 20%">发布时间：{{ itemData.publictime }}</p>
              <a @click="collectOrCancel">{{
                collectItemId != "" ? "已收藏" : "收藏"
              }}</a>
            </div>
            <div class="flex-row-between title-wrap">
              <p>政策分类：{{ itemData.policyClassification }}</p>
            </div>
            <div class="flex-row-between title-wrap">
              <p>行业类别：{{ itemData.sysCategory }}</p>
            </div>
          </div> -->
            <el-row class="line" style="line-height: 2;margin: auto">
              {{ itemData.name }}
            </el-row>
            <el-divider></el-divider>
            <el-row class="line">
              <el-col :span="2" class="line_label">政策文号:</el-col>
              <el-col :span="6" class="line_value"
                >{{ itemData.fwzh }}</el-col
              >
              <el-col :span="2" class="line_label">政策级别:</el-col>
              <el-col :span="6" class="line_value">{{ itemData.areaname }}</el-col>
              <el-col :span="2" class="line_label">发布日期:</el-col>
              <el-col :span="6" class="line_value">{{ itemData.publictime }}</el-col>
            </el-row>
            <el-row class="line">
              <el-col :span="2" class="line_label">政策分类:</el-col>
              <el-col :span="22" class="line_value"
                >{{ itemData.policyClassification }}</el-col
              >
            </el-row>
            <el-row class="line" style="margin-bottom:20px;">
              <el-col :span="2" class="line_label">行业类别:</el-col>
              <el-col :span="22" class="line_value"
                >{{ itemData.sysCategory }}</el-col
              >
            </el-row>
          <el-tabs
          style="width:1100px"
              v-model="activeName"
              type="border-card"
              @tab-click="handleClick"
              >
              <el-tab-pane label="政策详情" name="first"
              ><div class="detail-content" style="padding: 15px 50px" v-html="itemData.content"></div
              ></el-tab-pane>
              <el-tab-pane label="资助历史" name="second"
              ><div
                class="detail-content"
                v-html="itemData.aaa"
                v-if="itemData.aaa"
              ></div>
                <el-empty
                  style="width: 840px"
                  description="暂无数据"
                  v-else
                ></el-empty>
            </el-tab-pane>
          </el-tabs>

          <!-- <div class="attachment-container">
            <p>相关附件</p>
          </div> -->
          <!-- <img
            class="bottom-img"
            src="@/assets/images/detail_bottom_icon.png"
          /> -->
        </div>
        <!-- right -->
        <div class="detail-right">
          <!--政策的右边,相关政策改为相关通知 1是代表未当前是扶持政策页面-->
          <CardRight
            v-if="deptObject != null"
            @gotoDeclare="gotoDeclare"
            @clickIsFollow="clickIsFollow"
            :isPlolicy="collectType == 2 ? '1' : '0'"
            :deptObject="deptObject"
            :id="id"
          ></CardRight>
        </div>
        <!--登录提示弹窗-->
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
          <span>需要登录才能收藏，是否登录？</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="gotoLogin">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import CardRight from "@/components/cardRight";
import { Loading } from "element-ui";

export default {
  components: {
    CardRight,
  },
  data() {
    return {
      dialogVisible: false,
      id: "",
      offsetTop: 28,
      //部门信息,右边需要显示
      deptObject: null,
      menus: [
        {
          id: 0,
          name: "jibenxinxi",
          category: "政策详情",
        },
        {
          id: 1,
          name: "jibenxinxi",
          category: "政策解读",
        },
      ],
      itemData: null,
      //collectType 对应 type
      //1 通知 -> 2通知公告
      // 2 政策 -> 1项目政策
      // 3 信息公示 -> 3公开公示
      type: 0, //请求事项列表传的type参数
      collectType: 0, //收藏接口传的type参数
      collectItemId: "",
      activeName: "first",
    };
  },
  mounted() {
    const that = this;
    const id = that.$route.params.id; // 接收的参数ID
    that.id = id;
    that.type = that.$route.query.type;
    that.getNoticeInfo(id);

    //collectType 对应 type
    //1 通知 -> 2通知公告
    // 2 政策 -> 1项目政策
    // 3 信息公示 -> 3公开公示
    let type = this.type;

    this.collectType = 3;
    if (type == "1") {
      this.collectType = 2;
    }
    if (type == "2") {
      this.collectType = 1;
    }

    //查询当前项目是否被收藏
    this.isItemCollect();
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getTitle() {
      if (this.collectType == 1) {
        return "通知公告";
      }
      if (this.collectType == 2) {
        return "扶持政策";
      }
      if (this.collectType == 3) {
        return "信息公示";
      }
      return "";
    },
    gotoDeclare() {
      if (this.$store.getters.getUserInfo) {
        let childItem = this.$refs.guideCenter.childItem;
        this.$store.commit("updateCurrentDeclareItem", childItem);
        this.$router.push({
          name: "declare",
        });
      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    /**
     * 监听declareCeil子组件点击收藏事件
     */
    clickIsFollow(item) {
      //判断是否登录
      if (this.$store.getters.getUserInfo) {
        //登录
      } else {
        //未登录
        this.dialogVisible = true;
      }
    },
    /**
     * 登录提示框点击确定登录，跳转登录页面
     */
    gotoLogin() {
      console.log("跳转登录页面");
      this.$router.push({
        path: "/login",
      });
    },
    /**
     * 公告详细信息
     * @param {string} id 传项目id
     */
    getNoticeInfo(id) {
      let loadingnoticeDDetail = Loading.service({
        target: this.$refs.noticeDDetail,
      });
      let that = this;
      if (id) {
        this.$httpApi
          .get(`/dev-api/document/${id}`, {})
          .then((res) => {
            console.log("公告详细信息:", res);
            if (res.code == 200) {
              loadingnoticeDDetail.close();
              that.itemData = res.data;
              let data = res.data;
              this.deptObject = {
                deptName: data.deptname,
                deptId: data.deptid,
                fbjg: data.fbjg,
              };
            }
          })
          .catch((err) => {
            loadingnoticeDDetail.close();
          });
      }
    },
    //收藏或取消收藏
    collectOrCancel() {
      if (this.$store.getters.getUserInfo) {
        let flag = this.collectItemId != "";
        if (flag) {
          this.cancelCollect();
        } else {
          this.collect();
        }
      } else {
        this.$router.push({
          name: "login",
        });
      }
    },
    //查询当前是否已被收藏
    isItemCollect() {
      if (this.$store.getters.getUserInfo) {
        let identify = this.$store.getters.getUserInfo.shxydm;
        let id = this.id;
        let param = {
          identify: identify,
          collectionid: id,
          type: this.collectType,
          pageNum: 1,
          pageSize: 10,
        };
        let url = "/dev-api/collection/list";
        this.$httpApi
          .get(url, param)
          .then((res) => {
            console.log("是否收藏" + JSON.stringify(res));
            if (res.code == 200) {
              if (res.rows.length > 0) {
                this.collectItemId = res.rows[0].id;
              } else {
                this.collectItemId = "";
              }
            }
          })
          .catch((err) => {});
      }
    },
    //收藏
    collect() {
      let item = this.itemData;
      let identify = this.$store.getters.getUserInfo.shxydm;
      let param = {
        collectionName: item.name,
        collectionId: item.id,
        identify: identify,
        type: this.collectType,
      };

      this.$httpApi
        .post("/dev-api/collection/install", param)
        .then((res) => {
          console.log("添加收藏" + JSON.stringify(res));
          if (res.code == 200 && res.data) {
            this.collectItemId = res.id;
          }
        })
        .catch((err) => {});
    },
    //取消收藏
    cancelCollect() {
      let collectItemId = this.collectItemId;

      this.$httpApi
        .post("/dev-api/collection/delete/" + collectItemId, {})
        .then((res) => {
          console.log("取消收藏" + JSON.stringify(res));
          if (res.code == 200) {
            this.collectItemId = "";
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-size {
  width: 100%;
  height: 200px;
  background: url("../../assets/images/党建100周年2.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-info {
  height: 100%;
  display: flex;
  align-items: center;
  .banner-fMax {
    font-size: 40px;
  }

  .banner-other {
    margin-left: 20px;
    font-size: 20px;
  }
}
.policy-detail-content {
  margin-top: 30px;
  min-height: 400px;
}

.detail-wrap {
  width: 100%;
  align-items: flex-start;

  /* 左右 */
  .detail-left {
    flex: 1;
    align-self: flex-start;
    margin-right: 30px;
    overflow: hidden;
  }

  .detail-right {
    width: 300px;
  }
}

.detail-title {
  color: #333;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  h1 {
    width: 100%;
    align-self: flex-start;
    padding-bottom: 10px;
    border-bottom: 0.1875rem solid #0d1c28;

    // margin-bottom: 20px;
  }

  p {
    padding: 10px 0;
  }

  .title-wrap {
    width: 100%;
    height: 36px;
    color: #aaaaaa;
  }
}

.detail-content {
  line-height: 28px;
  font-size: 14px;
  margin: 20px 0;
  overflow: hidden;

  p {
    text-indent: 2rem;
  }
}

/* 附件 */
.attachment-container {
  width: 100%;

  p {
    padding-bottom: 0.3125rem;
    font-size: 1.25rem;
    border-bottom: 0.1875rem solid #0d1c28;
    color: #1d1e1f;
  }

  a {
    display: block;
    height: 40px;
    padding: 10px 0;
  }
}

/* 底部图片 */
.bottom-img {
  margin-top: 20px;
  width: 100%;
}
.line {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 24px;
  color: #333;
  font-weight: 600;
  .line_label {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  .line_value {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
}
</style>
